<template>
<div style="width:100%">
  <v-card>
    <v-toolbar :color="colores.primario" dark dense><span style="font-size:20px">HISTORIAL ESCALAMIENTOS</span></v-toolbar>
    <v-card-text class="pa-4" v-if="registro.geslogs">
        <div v-for="(item, index) in registro.geslogs" :key="index">
          <lecturaLogs :item="item" :index="index" :colores="colores"></lecturaLogs>
          <br>
        </div>
    </v-card-text>
  </v-card>
</div>
</template>

<script>
// import lecturaLogs from '@/components/crm/lectura_logsComponent.vue'
export default {
  name: 'lectura_escalamientosComponent',
  components: {
    lecturaLogs: () => import('@/components/crm/lectura_logsComponent.vue')
  },
  props: ['colores', 'registro'],
  data: () => ({
  }),
  created () {

  },
  methods: {

  }

}
</script>
